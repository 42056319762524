import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`DB Deadlifts 12-12-12\\@60% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`150ft Bear Crawl Buy In,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`21-KB SDHP’s (53/35)`}</p>
    <p>{`15-K2E’s`}</p>
    <p>{`9-Wall Ball 2 fer’s (20/14) `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=TqfgeVwQ3fQ"
      }}>{`https://www.youtube.com/watch?v=TqfgeVwQ3fQ`}</a></p>
    <p>{`150 Bear Crawl Cash Out.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start today.  For more info email
Eric at: Fallscitystrength\\@gmail.com.`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`The Ville will close after the 4:30 class next Friday for Oaks.  `}</strong></p>
    <p><strong parentName="p">{`*`}{`East will be closed on Saturday, Derby Day.  We will have one class
at the Ville at 10:00am.  All are invited so bring a friend!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      